<template>
  <section>
    <div v-if="showCompleteModal" class="uncheck-modal">
      <div>
        <div class="bold">Nice!</div>
        <div class="explanation">
          You’re one step closer to completing your onboarding material and
          finishing your house! Keep an eye on those notifications for updates
          from your leader.
        </div>
      </div>
      <div class="button-area">
        <div class="submit" @click="completeTasks()">Continue</div>
      </div>
    </div>
    <div class="divider" />
    <Help
      v-if="showModal"
      @showModal="(i) => (showModal = i)"
      @changeStatus="(selected, explanation) => handleAssistance(selected, explanation)"
      class="assistance-modal"
      :tasks="currentTask.subtasks"
      :subtask="true"
    />
    <div v-if="uncheckModal" class="uncheck-modal">
      <div>
        <div class="bold">Are you sure?</div>
        <div class="exlpanation">
          Unchecking a task affects your progress and notifies your trainer.
        </div>
      </div>
      <div class="button-area">
        <div class="back" @click="uncheckModal = false">Cancel</div>
        <div class="submit" @click="handleSubmit()">Submit</div>
      </div>
    </div>
    <h3 class="task-type">{{ topic }}</h3>
    <div class="task-container">
      <div class="task-header-container">
        <h2 class="task-header">{{ currentTask.name }}</h2>
        <!-- <StatusKeyItem :status="task.status" font-size="14" image-size="35" /> -->
      </div>
      <div class="divider" style="margin: 20px 0 30px" />
      <p>{{ currentTask.tooltip }}</p>
      <div class="subtasks-container">
        <div
          v-for="(splittedSubtask, column) in splitTasksArrayPairwise(
            currentTask.subtasks
          )"
          :key="column"
          class="splitted-subtask-container"
        >
          <SubtaskItem
            v-for="(subtask, index) in splittedSubtask"
            :key="subtask.name"
            :column="column"
            :subtask="subtask"
            :index="index"
            :task="currentTask"
            @changeStatus="(task, i, column) => updateStatus(task, i, column)"
          />
        </div>
      </div>
    </div>
    <!-- <div class="footer-container">
      <p class="review-text">Still in review?
        <router-link to="" class="contact-link">Contact to your trainer.</router-link>
      </p>
      <div style="display: flex; align-items: center; column-gap: 25px">
        <span>All done?</span>
        <SimpleButton label="Submit for review" class="submit-button" />
      </div>
    </div> -->
    <div class="button-area">
          <div @click="emit('tabClick', '/setting-up', '')" class="back">
                  <img class="chevron" :src="lt" alt="" /> Back</div>
   </div>
    <AssistanceButton v-if="showAssistanceButton" @click="showModal = true" />
  </section>
</template>

<script setup>
import {
  ref,
  watchEffect,
  defineProps,
  defineEmits,
  onBeforeMount,
  onMounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import StatusKeyItem from "@/components/StatusKeyItem";
import SubtaskItem from "@/components/SubtaskItem";
import SimpleButton from "@/components/ui/SimpleButton";
import splitTasksArrayPairwise from "@/utils/splitTasksArrayPairwise";
import ProgressAPI from "@/api/ProgressAPI";
import AssistanceButton from "@/components/AssistanceButton";
import Help from "@/components/Help";
import ChecklistAPI from "@/api/ChecklistAPI";
import lt from '@/assets/icons/lt.svg'
const showAssistanceButton = ref(true)
const showModal = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();
const uncheckModal = ref(false);
const currentMonth = ref(null);
const index = ref(null);
const column = ref(null);
const sub = ref();
const complete = ref(0);
const showCompleteModal = ref(false);
const topic = ref();
const completed = ref(0);
// const props = defineProps({
//   task: {
//     type: Object,
//     required: true
//   },
//   topic: {
//     required: true
//   },
//   column: {
//     required: true
//   }
// })
const currentTask = ref();
const currentSubtask = ref();
const handleAssistance = async (tasks, explanation)=>{
  const ids = []
  tasks.forEach(async task=>{
    ids.push(task.id)
    const update = {
      subtask_id: task.id,
      status: 'attention-required',
      subtask_status: 'attention-required'
    
    }
   await ChecklistAPI.updateSubtask(update)
  })
   let expl
  if(explanation) expl = explanation
  else expl = 'n/a'
  const payload = {
    problem: expl,
    status: 'active',
    subtask_ids: ids,
    task_ids: [],
  }
  await ChecklistAPI.updateAssistance(payload)
}
onMounted(async () => {
  await getTasks();
  //  const checklist = await ChecklistAPI.getChecklist()
  //    const technologyList =  checklist.find(task => task.name ==='Technology')
  //    const logisticsList =  checklist.find(task => task.name ==='Logistics')
  //   const  allTasks = technologyList.tasks.concat(logisticsList.tasks)
  //   currentTask.value = allTasks.find(item=>item.id===route.query.task)
  //   console.log(currentTask.value)
  //   topic.value = route.query.topic
});
  const emit = defineEmits({
  tabClick: (path) => typeof path === 'string',
})
const getTasks = async () => {
  completed.value = 0;
  const checklist = await ChecklistAPI.getChecklist();

  const technologyList = checklist.find((task) => task.name === "Technology");

  const logisticsList = checklist.find((task) => task.name === "Logistics");

  technologyList.tasks.forEach((task) => {
    task.subtasks.sort(function (a, b) {
      return a.order - b.order;
    });
    if (task.progresses)
      task.status = task.progresses?.find(
        (item) => item.task_id === task.id
      )?.status;
    else task.status = "available";
    if (task.subtasks) {
      task.subtasks.forEach((sub) => {
        sub.status = task.progresses?.find((item) => item.subtask_id === sub.id)
          ?.subtask_status
          ? task.progresses?.find((item) => item.subtask_id === sub.id)?.subtask_status
          : "available";
      });
    }
  });
  logisticsList.tasks.forEach((task) => {
    task.subtasks.sort(function (a, b) {
      return a.order - b.order;
    });
    if (task.progresses)
      task.status = task.progresses?.find(
        (item) => item.task_id === task.id
      )?.status;
    else task.status = "available";
    if (task.subtasks) {
      task.subtasks.forEach((sub) => {
        sub.status = task.progresses?.find((item) => item.subtask_id === sub.id)
          ?.subtask_status
          ? task.progresses?.find((item) => item.subtask_id === sub.id)?.subtask_status
          : "available";
      });
    }
     
  });
  const allTasks = technologyList.tasks.concat(logisticsList.tasks);
  currentTask.value = allTasks.find((item) => item.id === +route.query.task);
  console.log(currentTask);
  topic.value = route.query.topic;
  currentTask.value.subtasks.forEach((sub) => {
    if (sub.status === "completed" || sub.status === "in-review")
      completed.value++;
  });
     if (completed.value === currentTask.value.subtasks.length)
      showAssistanceButton.value = false;
};

// console.log('propsTask',props.task)
// const subtasks = ref(props.task.subtasks)
// const emit = defineEmits(['click', 'changeStatus', 'taskComplete'])
const completeTasks = async () => {
  const updateTask = {
    task_id: currentTask.value.id,
    status: "in-review",
    subtask_status: "in-review",
  };
  await ChecklistAPI.updateTask(updateTask);
  await router.push("/setting-up?complete=true");
};
const updateStatus = async (subtask, i, columnm) => {
  console.log(subtask);
  if (subtask.status === "in-review") {
    currentSubtask.value = subtask;
    uncheckModal.value = true;
  } else if (subtask.status !== "completed") {
    const updatedTask = {
      subtask_id: subtask.id,
      status: "in-review",
      subtask_status: "in-review",
    };
    await ChecklistAPI.updateSubtask(updatedTask);
    await getTasks();
    if (completed.value === currentTask.value.subtasks.length)
      showCompleteModal.value = true;
  }

  // sub.value=subtask
  // if (subtask.status === 'in-review') {
  //   uncheckModal.value = true
  // } else if (subtask.status !== 'completed') {
  //     emit('changeStatus', props.task, 'check', subtask, props.topic)
  //   complete.value++
  // }
  // console.log('subbubub:',subtasks.value )
  // if (complete.value === subtasks.value.length) showCompleteModal.value = true
};
const handleSubmit = async () => {
    const updatedTask = {
    task_id: currentTask.value.id,
    status: 'available',
    subtask_status: 'available'

  }
  showAssistanceButton.value = true
  const updateSubtask = {
    subtask_id: currentSubtask.value.id,
    status: "available",
    subtask_status: "available",
  };
  await ChecklistAPI.updateTask(updatedTask)
  await ChecklistAPI.updateSubtask(updateSubtask);
  await getTasks();
  uncheckModal.value = false;
  // console.log('uncheckkckckc')
  // complete.value--
  // uncheckModal.value = false
  //     emit('changeStatus', props.task, 'uncheck', sub.value, props.topic)
};
watchEffect(async () => {
  if (!store.state.user.id || !route.params.taskID) return;
  try {
    // task.value = await ProgressAPI.getMyTask(route.params.taskID)
  } catch (e) {
    console.error(e);
  }
});

// subtasks.value.forEach(item => {
//   if (item.status === 'completed' || item.status === 'in-review') complete.value++
// })
</script>

<style scoped>
.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 45px;
  border: 0;
  background: rgba(201, 204, 206, 0.38);
}
.bold {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  /* identical to box height */
  color: #000000;
}
.task-type {
  margin-left: 25px;
  margin-bottom: 30px;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  font-size: 18px;
  color: #ff8217;
  text-transform: uppercase;
}

.task-container {
  margin-bottom: 40px;
  padding: 25px;
  border-radius: 20px;
  background: white;
  width: 900px;
}
.button-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button-area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.task-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chevron{
  margin-right: 10px;
}
.back {
  /* border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000; */
    margin-right: 15px;
  margin-top: 20px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  text-align: right;
  text-decoration: none;
  font-size: 16px;
  color: #FF8217;
  cursor: pointer;
  
}
.task-header {
  margin: 0;
  font-size: 24px;
}
.explanation {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.subtasks-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 35px 0 25px;
  row-gap: 15px;
  column-gap: 25px;
}

.splitted-subtask-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 10px;
}


.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.uncheck-modal {
  width: 470px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  flex-direction: column;
  padding: 20px 60px 20px 60px;
  left: 43.5%;
  bottom: 33%;
  z-index: 1000;
  border: solid 1px black;
  /* transform: translate(-50%, -50%); */
}
.assistance-modal {
  position: fixed;
  left: 43.5%;
  bottom: 13%;
  z-index: 1000;
  /* transform: translate(-50%, -50%); */
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 25px;
  font-size: 16px;
}

.review-text {
  margin: 0;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
}
/* .back {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000;
} */

.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.contact-link {
  text-decoration: none;
  color: #ff8217;
}

.submit-button {
  padding: 0 30px;
  font-size: 14px;
}
</style>
